<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
       <img src="@/assets/images/logo.png" alt="" width="200" height="50">
          <!-- <h2 class="brand-text text-primary ml-1">
            Vuexy 
          </h2> -->
        </b-link>

        <b-card-title class="mb-1">
          {{$t('WelcometoDaaweny')}}
        </b-card-title>
        <b-card-text class="mb-2">
          {{$t('welcInSign')}}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              :label="$t('UserName')"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{$t('Password')}}</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>{{
        $t("ForgotPassword")
      }}</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="checkbox_remember_me"
                name="checkbox-1"
              >
              {{
        $t("RememberMe")
      }}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click="loginJWT"
            >
              {{ $t("Login")}}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-register-v1'}">
            <span>{{$t("Signup")}}</span>
          </b-link>
        </b-card-text>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'home'}">
            <span>{{$t("Continueasguest")}}</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            {{$t('or')}}
          </div>
        </div>

        <!-- social button -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@validations'
import {
BButton, BCard, BCardText, BCardTitle, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BLink
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: '',
      password: '',
      status: '',
      checkbox_remember_me: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods:{
     loginJWT() {
      debugger
      this.$vs.loading();
      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
        },
      };
      debugger;
      this.$store
        .dispatch("auth/loginJWT", payload)
        .then((res) => {
          this.$vs.loading.close();
          debugger;
          this.$acl.change(res.data.Data.userData.userRole);
          res.data.Data.userData.ability = 
           [
        {
          action: 'manage',
          subject: 'all',
        },
      ]
          this.$ability.update( res.data.Data.userData.ability)
          useJwt.setToken(res.data.Data.accessToken)
              useJwt.setRefreshToken(res.data.Data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(res.data.Data.userData))

          debugger;
          if (res.data.Data.userData.userRole == "patient") {
            if (
              res.data.Data.userData.Patient.CompletedRegisterationData != true
            )
              this.$router.push({
                name: "CompletedRegisterationData",
                params: { ID: res.data.Data.userData.Patient.ID },
              });
            else 
            this.$router.push("/").then(()=>{
              location.reload();
            });
            // this.$router.push("/Patient/Home");
          } else if (res.data.Data.userData.userRole == "doctor") {
            //  debugger
            //    if(!res.data.Data.userData.IsGeneralDoctor)
            //        this.$router.push({ name:"ReservedSurgery" });
            //    else
             this.$router.push("/doctor/Home").then(()=>{
              location.reload();
            });;
            //this.$router.push("/");

          } else if (res.data.Data.userData.userRole == "hospital")
             this.$router.push("/hospital/Home").then(()=>{
              location.reload();
            });
            //this.$router.push("/");
          else if (res.data.Data.userData.userRole == "hospitalgroup")
            this.$router.push("/hospital/ReservedSurgery");
        })

        .catch((err) => {
          debugger;
          this.$vs.loading.close();
          if (err && err.response.status == 400) {
            this.$vs.notify({
              title: this.$t("Error"),
              text: err.response.data.Message,
              iconPack: "feather",
              time: 5000,
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: this.$t("Error"),
              text: "Wrong User Name or Password",
              time: 5000,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
   
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
